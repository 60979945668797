<template>
<v-dialog
  ref="dialog"
  v-model="modal2"
  :return-value.sync="date"
  persistent
  width="290px"
  :disabled="disabled"
>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
      v-model="date"
      v-bind:label="$t(label)"
        v-bind:value="$t(date)"
        prepend-icon="mdi-calander-outline"
        readonly
        v-bind="attrs"
        v-on="on"
        @input="broadCastDate"
      ></v-text-field>
    </template>
    <v-date-picker
      v-if="modal2"
      v-model="date"
      full-width
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="modal2 = false"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.dialog.save(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>

export default {
  name: 'ABRDatePicker',
    props:{
      passedDate:String,
      passedLabel:String,
      disabled:{
        type:Boolean,
        default:false
      }
    },
  created(){
    this.$watch(
          "date",() => {this.broadCastDate()
          },
          {deep: true}
    );
    this.$watch(
          "passedDate",() => {this.resetDate()
          },
          {deep: true}
    );
  },


  data(){
    return{
      date: this.passedDate,
      modal2:false,
      label:this.passedLabel
    };
  },
  methods:{
    //  $t(value) {
    //   return value;
    $t(value){
      return value;
    },
    broadCastDate(){
         this.$emit("dateset", this.date);
      },
    resetDate(){
      this.date = this.passedDate;
    }
  
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>