<template>
  <div class="site" label="Station Visit">
    <div>
      
      <v-container>
        <v-subheader inset>Station Visit Data</v-subheader>
        <v-row>
          <v-col
            sm = "10"
            md="2">
            <v-select
            v-model="tributary"
            :items="refTributary"
            item-value="code"
            item-text="title"
            label="Tributary"
            @change="saveRecord"
            dense
            :disabled="enabled"
            ></v-select>
          </v-col>
            <v-col sm = "10"
              md="2">
              <v-select 
              v-model="station"
              v-on="handler"
              :items="filteredSiteList"
              item-value="code"             
              item-text="title"
              label="Station"
              dense
              :disabled="enabled"
            />
          </v-col>
            
          <v-col >
            <ABRDate  :passedLabel="SampleDateLabel" :passedDate="visit_date" v-on="handler" :disabled="enabled"> </ABRDate>
          </v-col>
        </v-row>
        <v-row>
           
             <v-col 
              sm = "9"
              md="2">              
              <v-text-field id="investigators"
              v-bind:disabled="!enabled"
                v-model="currentRecord.investigators"
                label="Investigators" 
                @blur="saveRecord"/>   
                   
            </v-col>
          
          <v-col sm = "9"
              md="1">
              <v-text-field
              v-bind:disabled="!enabled"
                v-model="currentRecord.temperature_c" 
                label="Temperature (C)"
                @blur="saveRecord"
                />       
            </v-col>
            <v-col sm = "9"
              md="1">
              <v-select
              v-bind:disabled="!enabled"
                v-model="currentRecord.gear" 
                label="Gear"
                :items="refGear"
            item-value="code"
            item-text="title"
            @change="saveRecord"
                />       
            </v-col>
             <v-col sm = "10"
              md="3">
              <v-text-field
              v-bind:disabled="!enabled"
                v-model="currentRecord.comments" 
                label="Comment"
                @blur="saveRecord"/>       
            </v-col>
        </v-row>
        <v-row>
          
          <v-col sm="1" md="2">
          <v-btn large color="primary" elevation="2" v-on:click="saveRecord">
          Submit
          </v-btn>
          </v-col>
         
          <v-col sm="1" md="2">
          <v-btn large color="primary" elevation="2" v-on:click="newRecord">
          New Record
          </v-btn>
          </v-col>
        </v-row>
      </v-container>
  </div>
  </div>
</template>

<script>

import ABRDate from '../components/ABRDate'
import support from '../plugins/support'

export default {
  name: 'SiteVisit',
  
  components:{ABRDate},
  props:{
   record:Object
  },
  created(){
    this.$watch(
          'record',() => {
             this.currentRecord= this.record;
          },
          {deep: true}
    );
 
    this.$watch(
          'station',() => {this.getStationVisit()
          },
          {deep: true}
    );
    this.$watch(
          'visit_date',() => {this.getStationVisit()
          },
          {deep: true}
    );

  },
  async mounted(){
    this.currentRecord = this.record
      },


  data(){
    return{
      tableName:'site_visit',
      handler:{
        input:this.getStationVisit,
        dateset:this.setDateValue 
      },
     siteVisit : [],
     
     SampleDateLabel: "Sample Date",
    currentRecord: this.record,
    
     
         };    
    
  },

  computed:{
    enabled() {
     return  ((this.currentRecord.global_id !== '') && (this.currentRecord.global_id !== undefined))
  },
    visit_date:{
      get(){return this.$store.state.visit_date},
      set(value){
        this.$store.commit('updateAttribute',{attribute:'visit_date',value:value})
      }
    },
    station:{
      get(){return this.$store.state.station},
      set(value){this.$store.commit('updateAttribute',{attribute:'station',value:value})
    },
  },
    tributary: {
    get() {return this.$store.getters['attribute']('tributary')},
      set(value) {
        this.$store.commit('updateAttribute',{attribute:'tributary', value:value})
      }
  },
  filteredSiteList() {
      return this.site.filter((el)=>
        (el.tributary === this.tributary)) //The original site table did not include tributary so we need to handle undefined tributary field
    },
  site() {
      return this.$store.getters.refTable('site')
    },
    refTributary() {
      return this.$store.getters.refTable('tributary')
    },
    refGear(){
      return this.$store.getters.refTable('gear')
    }
},
  methods:{
       
    setDateValue(newdate){
      this.visit_date= newdate;
      this.currentRecord.visit_date = newdate;
      this.getStationVisit()
    },
    assignStringDate(newdate){
        let date = new Date();
        date.setMonth(newdate.split("/")[1])
        date.setYear(newdate.split("/")[0])
        date.setDate(newdate.split("/")[3])
        return date;
    },

    saveRecord(){
      this.currentRecord.station = this.currentRecord.station === '' ? this.station : this.currentRecord.station
      this.currentRecord.visit_date = this.currentRecord.visit_date === '' ? this.visit_date : this.currentRecord.visit_date
      support.saveRecord(
                  this.tableName, this.currentRecord)
            .then((result) => {
              this.$store.commit('updateAttribute',{attribute: `current_${this.tableName}`, value:result}) 
            })
    },
  getStationVisit(){
    //this.generateSampleId()
    if (this.currentRecord.ts == ''){
      this.currentRecord.ts = new Date();
      
    }
    this.currentRecord.station = this.station;
    if ((this.visit_date !== '') && (this.station !== '')){
      let endpoint = "site_visit/visit_date/" + this.visit_date + "/station/" + this.station;
    support.getApiData('dataTables',"site_visit", endpoint)
    .then((result) => {
      this.$store.commit('updateAttribute',{attribute:'current_site_visit',value: result.length > 0 ? result[0] : this.currentRecord})
      }  )
    }
  },
  newRecord(){
    //TODO save old record and table data
    this.station = ""
    //this.visit_date = new Date().toISOString().slice(0, 10);
    this.visit_date = ""
    this.$store.commit('updateAttribute',{attribute:'current_site_visit',value: support.newRecord('site_visit')})
    this.$store.commit('refreshRecords',{tableType:'dataTables',tableName:'site_visit',records:[]})
   
  },
 
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>
